import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';

import { type DtoProgression } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { StackedSquaresIcon } from '../../../icons/StackedSquaresIcon';
import { ActionsMenu } from '../Components/ActionMenu';
import { AssessmentScoreButton } from '../Components/AssessmentScoreButton';

interface AssignedMemberRowProps {
  uid: string;
  name: string;
  group: string;
  assignedOn: string;
  addedBy: string;
  completed: boolean;
  directProgress?: number | null;
  onDirectUnassign?: () => void;
  onIndirectUnassign?: () => void;
  isDirect?: boolean;
  isIndirect?: boolean;
  isExternal?: boolean;
  assessmentScore?: number;
  onClick?: () => void;
  progression?: DtoProgression;
}

export function AssignedMemberRow({
  name,
  group,
  assignedOn,
  addedBy,
  completed,
  directProgress,
  onDirectUnassign,
  onIndirectUnassign,
  onClick,
  isDirect = false,
  isIndirect = false,
  isExternal = false,
  progression,
}: AssignedMemberRowProps) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
      interactive: true,
      offset: [0, 8],
      visible: isTooltipOpen,
      onVisibleChange: (next) => setTooltipOpen(next),
      trigger: 'hover',
    });

  let tooltipContent = '';
  if (isIndirect) {
    tooltipContent = 'Assigned via Course Stack';
  } else if (isExternal) {
    tooltipContent = 'Learner from external LMS system';
  }

  const actions = [];
  if (isDirect) {
    actions.push({
      label: 'Unassign Enrollment',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick:
        onDirectUnassign ?? (() => toast.error('Error unassigning member.')),
      variant: 'danger' as const,
    });
  } else if (isIndirect) {
    actions.push({
      label: 'Manage through Stack',
      icon: <StackedSquaresIcon className='w-6 h-6 fill-current pr-2' />,
      onClick:
        onIndirectUnassign ??
        (() =>
          toast.error(
            'Please manage this assignment via the course stack details.'
          )),
      variant: 'default' as const,
    });
  }
  // No action menu items for external learners

  return (
    <div
      className={`
        relative w-full rounded-lg px-4 py-3 bg-[#17171A] hover:bg-[#1f1f22] transition-colors
        flex flex-col md:flex-row md:items-center border-lp-gray-003 border ${
          onClick ? 'cursor-pointer' : 'cursor-default'
        }
      `}
      onClick={onClick}
    >
      {isExternal ? (
        <>
          <div className='md:w-1/5 flex-1 text-white font-medium text-sm mb-1 md:mb-0 pr-1 flex items-center truncate'>
            <span className='truncate'>{name}</span>
          </div>

          <div className='md:w-1/5 flex-1 text-white/90 text-sm mb-1 md:mb-0'>
            {assignedOn}
          </div>

          <div className='flex-1 text-sm font-semibold mb-2 md:mb-0 text-white/90'>
            {completed ? '100%' : `${directProgress ?? 0}%`}
          </div>
          <div className='flex-1 mb-2 md:mb-0'>
            <AssessmentScoreButton
              progression={progression}
              learnerName={name}
            />
          </div>
        </>
      ) : (
        // Regular member layout
        <>
          <div className='md:w-1/5 flex-1 text-white font-medium text-sm mb-1 md:mb-0 pr-1 flex items-center truncate'>
            <span className='truncate'>{name}</span>
            {isIndirect && !isDirect && (
              <span ref={setTriggerRef} className='ml-2'>
                <StackedSquaresIcon className='w-4 h-4 fill-current text-gray-400' />
              </span>
            )}
          </div>

          <div className='md:w-1/5 flex-1 text-white/90 text-sm mb-1 md:mb-0'>
            {group}
          </div>
          <div className='md:w-1/5 flex-1 text-white/90 text-sm mb-1 md:mb-0'>
            {assignedOn}
          </div>
          <div className='md:w-1/5 flex-1 text-white/90 text-sm mb-1 md:mb-0'>
            {addedBy}
          </div>
          <div className='flex-1 text-sm mb-2 md:mb-0 text-white/90'>
            {completed ? '100%' : `${directProgress ?? 0}%`}
          </div>
          <div className='md:w-1/5 flex-1 mb-2 md:mb-0'>
            <AssessmentScoreButton
              progression={progression}
              learnerName={name}
            />
          </div>

          {/* Actions menu */}
          <div className='relative flex items-center justify-end w-12 self-end md:self-auto text-left'>
            <ActionsMenu actions={actions} className='text-left w-50' />
          </div>
        </>
      )}

      {/* Tooltip (shown only if the member is assigned via a stack) */}
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'z-50 w-50 bg-black text-white rounded-xl p-1 border border-secondary',
          })}
        >
          <div className='text-xs whitespace-pre-line text-center'>
            {tooltipContent}
          </div>
        </div>
      )}
    </div>
  );
}

export function useNavigateToLearnerProfile() {
  const navigate = useNavigate();
  return useLiveCallback((uid: string) => {
    navigate($path('/learning/admin/learners/:id/profile', { id: uid }));
  });
}
